import * as url from "./url_helper"
import { get, post, del } from "./api_helper"

export const getCompany = () => get(url.GET_COMPANY)

export const deleteCompany = companyId =>
  del(url.DELETE_COMPANY, { data: { companyId } })

export const addCompanyData = companyData =>
  post(url.ADD_COMPANY_DATA, companyData)

export const updateCompanyData = companyData =>
  post(url.UPDATE_COMPANY_DATA, companyData)

import * as url from "./url_helper"
import { get, post, del } from "./api_helper"

export const getUsers = () => get(url.GET_USERS)

export const deleteUser = userId => del(url.DELETE_USERS, { data: { userId } })

export const addUsersData = userData => post(url.ADD_USERS_DATA, userData)

export const updateUserData = user => post(url.UPDATE_USERS_DATA, user)

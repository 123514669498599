import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_COMPANIES,
  DELETE_COMPANY,
  ADD_NEW_COMPANY,
  UPDATE_COMPANY,
} from "./actionTypes"
import {
  getCompanySuccess,
  getCompanyFail,
  addCompanySuccess,
  addCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
} from "./actions"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// Include Both Helper File with needed methods
import {
  getCompany,
  addCompanyData,
  updateCompanyData,
  deleteCompany,
} from "../../api/company"

function* fetchCompany() {
  const response = yield call(getCompany)

  if (response.status === 202) {
    yield put(getCompanySuccess(response.data))
  } else {
    if (response.response.data.message == "Token Expired") {
      localStorage.removeItem("tryUser")
    }

    yield put(getCompanyFail(response.response.data))
  }
}

function* onAddCompanyData({ payload: companyData }) {
  const response = yield call(addCompanyData, companyData)

  if (response.status === 202) {
    yield put(addCompanySuccess(companyData))
    toast.success("User Added Successfully", { autoClose: 2000 })
  } else {
    yield put(addCompanyFail(response.response.data))
    toast.error("User Added Failed", { autoClose: 2000 })
  }
}

function* onUpdateCompanyData({ payload: company }) {
  const response = yield call(updateCompanyData, company)

  if (response.status === 202) {
    yield put(updateCompanySuccess(company))
    toast.success("Company Update Successfully", { autoClose: 2000 })
  } else {
    yield put(updateCompanyFail(response.response.data))
    toast.error("Company Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteCompany({ payload: company }) {
  const response = yield call(deleteCompany, company)

  console.log(response)

  if (response.status === 202) {
    yield put(deleteCompanySuccess(company))
    toast.success("Company Deleted Successfully", { autoClose: 2000 })
  } else {
    yield put(deleteCompanyFail(response.response.data))
    toast.error("Company Deleted Failed", { autoClose: 2000 })
  }
}

function* companySaga() {
  yield takeEvery(GET_COMPANIES, fetchCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompanyData)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(ADD_NEW_COMPANY, onAddCompanyData)
}

export default companySaga

import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
} from "./actionTypes"

export const getCompany = () => ({
  type: GET_COMPANIES,
})

export const getCompanySuccess = company => ({
  type: GET_COMPANIES_SUCCESS,
  payload: company,
})

export const getCompanyFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
})

export const addCompany = companyData => ({
  type: ADD_NEW_COMPANY,
  payload: companyData,
})

export const addCompanySuccess = companyData => ({
  type: ADD_COMPANY_SUCCESS,
  payload: companyData,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = companyData => ({
  type: UPDATE_COMPANY,
  payload: companyData,
})

export const updateCompanySuccess = companyData => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: companyData,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const deleteCompany = companyData => ({
  type: DELETE_COMPANY,
  payload: companyData,
})

export const deleteCompanySuccess = companyData => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: companyData,
})

export const deleteCompanyFail = companyData => ({
  type: DELETE_COMPANY_FAIL,
  payload: companyData,
})

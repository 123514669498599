import PropTypes from "prop-types"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

const Login = props => {
  //meta title
  document.title = "Login | Try Giraffe"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@iotreeminds.com" || "",
      password: "admin@123" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  // const signIn = type => {
  //   dispatch(socialLogin(type, props.router.navigate))
  // }

  //for facebook and google authentication
  // const socialResponse = type => {
  //   signIn(type)
  // }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <section className="vh-100  overflow-hidden">
        <Row>
          <Col
            className="bg-primary vh-100 d-flex align-items-center justify-content-center d-none d-md-flex"
            lg={6}
          >
            <Row className="d-grid place-items-center text-white">
              <h2 className="fw-bolder fs-1 ">Try Girafee</h2>
              <p className="fs-5 px-2 fw-bold">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Distinctio,
              </p>
            </Row>
          </Col>
          <Col className="vh-100 px-5 d-flex align-items-center" lg={6}>
            <Container className="px-md-5">
              <Row className="p-1">
                <h1 className="fw-bold">Admin Panel</h1>
                {/* <p>Welcome back to your account</p> */}
              </Row>
              <div className="p-2">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  <div className="mb-3">
                    <Input
                      name="email"
                      className="form-control p-3 bg-transparent fo "
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Input
                      className="p-3 bg-transparent"
                      name="password"
                      value={validation.values.password || ""}
                      type="password"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mt-4 text-center">
                    <Link to="/forgot-password" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Forgot your password?
                    </Link>
                  </div>

                  <div className="mt-3 d-grid">
                    <button className="btn btn-primary btn-block" type="submit">
                      Log In
                    </button>
                  </div>
                </Form>
              </div>
            </Container>
          </Col>
        </Row>
      </section>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

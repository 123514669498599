const baseUrl = "http://dev.iotreeminds.com:3013"

export const ADMIN_LOGIN = `${baseUrl}/admin-panel/auth/login`

export const GET_USERS = "/admin-panel/user/list-users"
export const DELETE_USERS = "/admin-panel/user/delete-user"
export const ADD_USERS_DATA = "/admin-panel/user/create-user"
export const UPDATE_USERS_DATA = "/admin-panel/user/update-user"

export const GET_COMPANY = `/admin-panel/company/list-companies`
export const DELETE_COMPANY = "/admin-panel/company/delete-company"
export const ADD_COMPANY_DATA = "/admin-panel/company/create-company"
export const UPDATE_COMPANY_DATA = "/admin-panel/company/update-company"

import axios from "axios"

//pass new generated access token here
const token = localStorage.getItem("tryUser")

//apply base url for axios
const API_URL = "http://dev.iotreeminds.com:3013"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response)
    .catch(error => error)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response)
    .catch(error => error)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response)
    .catch(error => error)
}

import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo, useRef } from "react"
import TableContainer from "components/Common/TableContainer"
import { UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import {
  Container,
  Card,
  CardBody,
  Col,
  Modal,
  Input,
  Label,
  Form,
  Row,
  ModalHeader,
  ModalBody,
  FormFeedback,
} from "reactstrap"

import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import {
  getCompany as onGetCompany,
  addCompany as onAddCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
} from "../../store/company/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"

const Company = props => {
  //meta title
  document.title = "Company | Try-Giraffes Admin Panel"
  const dispatch = useDispatch()
  const [newCompany, setNewCompany] = useState()

  //formik validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (newCompany && newCompany.name) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Company Name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCompany = {
          companyId: newCompany._id,
          name: values.name,
        }
        // update user
        dispatch(onUpdateCompany(updateCompany))
        setIsEdit(false)
        validation.resetForm()
      } else {
        const newCompanyData = {
          name: values["name"],
        }
        // save new user
        dispatch(onAddCompany(newCompanyData))
        validation.resetForm()
      }
      toggle()
    },
  })

  const selectCompanyState = state => state.company
  const CompanyStateProperties = createSelector(
    selectCompanyState,
    Company => ({
      companies: Company.companies,
      loading: Company.loading,
    })
  )

  const { companies, loading } = useSelector(CompanyStateProperties)

  const [companyList, setCompanyList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        Header: "Sl/No",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps, index) => (
          <>
            <span>{index + 1}</span>
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const companyData = cellProps.row.original
                  handleCompanyEdit(companyData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const companyData = cellProps.row.original
                  onClickDelete(companyData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompany())
      setIsEdit(false)
    }
  }, [dispatch, companies])

  useEffect(() => {
    setNewCompany(companies)
    setIsEdit(false)
  }, [companies])

  useEffect(() => {
    if (!isEmpty(companies) && !!isEdit) {
      setNewCompany(companies)
      setIsEdit(false)
    }
  }, [companies])

  const toggle = () => {
    setModal(!modal)
  }

  const handleCompanyEdit = arg => {
    const company = arg

    setNewCompany({
      _id: company._id,
      name: company.name,
    })
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const handleAddNewComapny = () => {
    setCompanyList("")
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    dispatch(onGetCompany())
  }, [dispatch])

  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = company => {
    setNewCompany(company)
    setDeleteModal(true)
  }

  const handleDeleteCompany = () => {
    if (newCompany && newCompany._id) {
      dispatch(onDeleteCompany(newCompany._id))
    }

    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCompany}
        onCloseClick={() => setDeleteModal(false)}
        Title={"Are you sure you want to Delete the Company."}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("company")}
            breadcrumbItem={props.t("company")}
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={companies}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    iscustomPageSizeOptions={true}
                    handleUserClick={handleAddNewComapny}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    buttonTitle={"Add New Company"}
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          )}

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Company" : "Add Company"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Enter Company Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-accent save-user"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

Company.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Company)

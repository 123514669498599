import { call, put, takeEvery, select } from "redux-saga/effects"

import {
  GET_USERS,
  UPDATE_USER,
  DELETE_USER,
  ADD_NEW_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  deleteUserSuccess,
  deleteUserFail,
  addUserSuccess,
  addUserFail,
  updateUserSuccess,
  updateUserFail,
} from "./actions"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// Include Both Helper File with needed methods
import {
  getUsers,
  addUsersData,
  deleteUser,
  updateUserData,
} from "../../api/users"

function* fetchUsers() {
  const response = yield call(getUsers)

  console.log(response)

  if (response.status === 202) {
    yield put(getUsersSuccess(response.data))
  } else {
    yield put(getUsersFail(response.response.data))
  }
}

function* onAddUserData({ payload: userData }) {
  const response = yield call(addUsersData, userData)

  console.log(response)

  if (response.status === 202) {
    yield put(addUserSuccess(response.data.userDetails._doc))
    toast.success("User Added Successfully", { autoClose: 2000 })
  } else {
    yield put(addUserFail(response.response.data))
    toast.error("User Added Failed", { autoClose: 2000 })
  }
}

function* onDeleteUser({ payload: user }) {
  const response = yield call(deleteUser, user)

  console.log(response)

  if (response.status === 202) {
    yield put(deleteUserSuccess(user))
    toast.success("User Deleted Successfully", { autoClose: 2000 })
  } else {
    yield put(deleteUserFail(response))
    toast.error("User Deleted Failed", { autoClose: 2000 })
  }
}

function* onUpdateUserData({ payload: user }) {
  const response = yield call(updateUserData, user)

  const companyDetails = yield select(state =>
    state.company.companies.find(company => company._id === user.company)
  )

  const newUserData = {
    _id: user.userId,
    name: user.name,
    email: user.email,
    password: user.password,
    company: companyDetails,
  }

  if (response.status === 202) {
    yield put(updateUserSuccess(newUserData))
    toast.success("User Update Successfully", { autoClose: 2000 })
  } else {
    yield put(updateUserFail(response.response.data))
    toast.error("User Update Failed", { autoClose: 2000 })
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(UPDATE_USER, onUpdateUserData)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(ADD_NEW_USER, onAddUserData)
}

export default usersSaga

import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

//Include Both Helper File with needed methods
import { postJwtLogin } from "api/auth"

function* loginUser({ payload: { user, history } }) {
  const response = yield call(postJwtLogin, {
    email: user.email,
    password: user.password,
  })

  console.log(response)

  if (response.status === 202) {
    localStorage.setItem("tryUser", response.data.token)
    yield put(loginSuccess(response.data))

    history("/")
  } else {
    yield put(apiError(response.response.data.message))
  }
}

function* logoutUser({ payload: { history } }) {
  localStorage.removeItem("tryUser")

  history("/login")
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

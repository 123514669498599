import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { size, map } from "lodash"

const Email = cell => {
  return cell.value ? cell.value : ""
}

const Companies = cell => {
  return (
    <>
      {map(
        cell.value,
        (company, index) =>
          index < 2 && (
            <Link
              to="#"
              className="badge badge-soft-primary font-size-11 m-1"
              key={"_skill_" + cell.value + index}
            >
              {company}
            </Link>
          )
      )}
      {size(cell.value) > 2 && (
        <Link
          to="#"
          className="badge badge-soft-primary font-size-11 m-1"
          key={"_skill_" + cell.value}
        >
          {size(cell.value) - 1} + more
        </Link>
      )}
    </>
  )
}

const Password = cell => {
  return cell.value ? cell.value : ""
}

export { Email, Companies, Password }
